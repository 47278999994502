import React,{useState} from 'react';
import '../index.css'
function Navbar() {
    const [show,setShow]=useState(false);
  return <>
     <nav class="navbar navbar-expand-lg navbar-light ">
  <div class="container nav-container-custom-style">
  <div> <a class="navbar-brand" href="/">
          {/* <span className="logo">Logo</span> */}
          <img className='technovita-logo' src="/Images/logo.png"/>
         
          </a>
          <p className='logo-text'>Technovita</p>
          <p className='logo-text-second '>solution</p>

          </div>
   
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
    data-bs-target="#navbarSupportedContent" 
    aria-controls="navbarSupportedContent" aria-expanded="false" 
    aria-label="Toggle navigation"
    onClick={()=>setShow(!show)}
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class={`collapse navbar-collapse ${show ? "show":" " }`} >
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/">Home</a>
        </li>
        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Services
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className='dropdown-item'><a class="dropdown-item" href="#">Catalogue Services</a>
            
            {/* starting catalogue submenu */}

            <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="/Flipkart-Catalogue"> Flipkart Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item " href="/services/amazon-catalogue-services"> Amazon Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/services/meesho-Catalogue">Meesho Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/paytm-Catalogue">Paytm Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/ebay-Catalogue">Ebay Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/myntra-Catalogue">Myntra Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/nykaa-Catalogue">Nykaa Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/shopee-Catalogue">Shopee Cataloging Services</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/services/ajio-Catalogue">Ajio Cataloging Services</a>
              </li>
            </ul>
            {/* ending catalogue submenu */}

            
            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="/">Account Management Service</a>
 {/* starting Management submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="/flipkart-account-management"> Flipkart Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/amazon-account-management"> Amazon Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/meesho-account-management">Meesho Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/paytm-account-management">Paytm Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/ebay-account-management">Ebay Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/myntra-account-management">Myntra Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/nykaa-account-management">Nykaa Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/shopee-account-management">Shopee Account Management </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/ajio-account-management">Ajio Account Management </a>
              </li>
            </ul>
            {/* ending Management submenu */}

            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Account Creation Services</a>
            
            {/* starting Account Creation submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-create-a-seller-account-on-flipkart/"> Flipkart Account Creation </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/steps-to-become-a-seller-on-amazon/"> Amazon Account Creation </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-get-onboarded-on-myntra/">Myntra Account Creation </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-create-an-account-on-nykaa/">Nykaa Account Creation </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-register-as-seller-on-ajio/">Ajio Account Creation </a>
              </li>
            </ul>
            {/* ending Account Creation submenu */}

            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Sales Boost Services</a>
             {/* starting Sales Boost  submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-increase-sales-on-flipkart/"> Flipkart Sales Boost Services </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-increase-sales-on-amazon/"> Amazon Sales Boost Services </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-boost-sales-on-meesho/">Meesho Sales Boost Services </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/paytm-sales-boost-services/">Paytm Sales Boost Services </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/shopee-sales-boost-services/">Shopee Sales Boost Services </a>
              </li>
            </ul>
            {/* ending Sales Boost  submenu */}
            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Seller Registration Services</a>
              {/* starting Saler Registration  submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-create-a-seller-account-on-flipkart/"> Flipkart Seller Registration </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/steps-to-become-a-seller-on-amazon/"> Amazon Seller Registration </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-get-onboarded-on-myntra/" >Myntra Seller Registration </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-create-an-account-on-nykaa/">Nykaa Seller Registration </a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="http://blog.technovitasolution.com/how-to-register-as-seller-on-ajio/">Ajio Seller Registration </a>
              </li>
            </ul>
            {/* ending seller  Registration  submenu */}
            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Onboarding Services</a>
             {/* starting Payment Reconciliation services  submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="/how-to-get-onboarded-on-ajio"> Ajio Onboarding Service</a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/how-to-get-onboarded-on-myntra"> Myntra Onboarding Service </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/how-to-get-onboarded-on-nykaa">Nykaa Onboarding Service </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
            </ul>
            {/* ending  Payment Reconciliation services submenu */}
            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Content Writing Services </a>
             {/* starting Content writing services  submenu */}

 <ul class="dropdown-submenu">
              <li>
                <a class="dropdown-item" href="/flipkart-content-writing"> Flipkart Content Writing </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/amazon-content-writing"> Amazon Content Writing </a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/meesho-content-writing">Meesho Content Writing</a>
              </li>
              <li><hr class="dropdown-divider"/></li>
              <li>
                <a class="dropdown-item" href="/myntra-content-writing">Myntra Content Writing</a>
              </li>
              <li><hr class="dropdown-divider"/></li>

              <li>
                <a class="dropdown-item" href="/nykaa-content-writing">Nykaa Content Writing</a>
              </li>
            </ul>
            {/* ending  Content Writing services submenu */}
            </li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item " href="#" >Website Development</a>
            </li>



          </ul>
        </li>

        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Calculator
          </a>
          <ul class="dropdown-menu " aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="https://sellerguide.technovitasolution.in/calculator/amazon">Amazon Calculator</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="https://sellerguide.technovitasolution.in/calculator/amazon">Amazon Fba Calculator</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="https://sellerguide.technovitasolution.in/calculator/ajio">AJIO calculator</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="https://sellerguide.technovitasolution.in/calculator/myntra">Myntra calculator</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="https://sellerguide.technovitasolution.in/calculator/flipkart">Flipkart calculator</a></li>


          </ul>
        </li>
    
        <li class="nav-item">
          <a class="nav-link" href="http://blog.technovitasolution.com/">Blogs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/about-us">About Us</a>
        </li>
      </ul>
    <a  class="btn payu-btn " href="https://pmny.in/Zrr13hibC5oW">PAY NOW WITH PayU Money</a>
    </div>
  </div>
</nav> 
  </>;
}

export default Navbar;
